
import './style.scss';
gsap.registerPlugin('ScrollTrigger')
gsap.config({ 
    nullTargetWarn: false, 
  });

const arrowPointer = document.querySelector('.start-shaking span');
const yourHand = document.querySelector('.your-hand');
const myHand = document.querySelector('.my-hand');
const salamHand = document.querySelector('.shake-hands');
const salamText = document.querySelector('.meet');
let salamTl , masterTl;

const boxes = gsap.utils.toArray('.home-article-box');
const linesLeft = gsap.utils.toArray('.rotate-left');
const linesRight = gsap.utils.toArray('.rotate-right');
const starEye =document.querySelector('#star-eye');

// generate copy write date
document.addEventListener('DOMContentLoaded', function () {
    let now = new Date();
    let currentYear = now.getFullYear();
    const yearText = document.getElementById("year-copyright");
    yearText.innerText=currentYear;


        // ----------- end ------------------
});
// preloader

// // console.log('preloader.js is working')
// const caoMoji=['(＠＾▽＾＠);','(* ^ ^ *);','（>﹏<）;','( *﹏* );','( ^_* );',
//                 '(o^▽^o);','ヽ(o^ ^o)ﾉ;','(◕‿◕);','(✯◡✯);','( T﹏T );'];
// // const random= Math.floor(Math.random() * caoMoji.length-1)
// const preloaderElemnt =document.getElementById('preloader-caoMoji');
// const waitingDots =document.getElementsByClassName('loader-dot');
// const mywaitingDots =Array.prototype.slice.call(waitingDots);
// const preloadContainter = document.getElementById('preloader');
// const page =document.getElementById('page');

// function isLoading(){
   
//     const random= Math.floor(Math.random() * caoMoji.length);
//     preloaderElemnt.innerHTML=`${caoMoji[random]}`
//     // console.log(random)
// }

// let load = setInterval(isLoading,1000)



// function doneLoading(){
//     mywaitingDots.forEach((dot)=>{
//         dot.style.animation = "unset";
//     })
//     clearInterval(load);
//     preloadContainter.style.display = "none"
//     page.classList.remove('stop-animation');
// }


//  window.onload = doneLoading
//  // navigation-------------------------

( 
	
	function() {
		
	
	const siteNavigation = document.getElementById( 'site-navigation' );
	const htmlPage = document.querySelector('body');
	const page = document.querySelector('.site');
	const toggleBtn = document.querySelector('.menu-toggle');
	const mainMenu = document.querySelector('.main-navigation');
	const   mainMenuItmes = document.querySelectorAll('.main-navigation .menu-item a');
	// Return early if the navigation doesn't exist.
	if ( ! siteNavigation ) {
		return;
	}

	const button = siteNavigation.getElementsByTagName( 'button' )[ 0 ];

	// Return early if the button doesn't exist.
	if ( 'undefined' === typeof button ) {
		return;
	}

	const menu = siteNavigation.getElementsByTagName( 'ul' )[ 0 ];

	// Hide menu toggle button if menu is empty and return early.
	if ( 'undefined' === typeof menu ) {
		button.style.display = 'none';
		return;
	}

	if ( ! menu.classList.contains( 'nav-menu' ) ) {
		menu.classList.add( 'nav-menu' );
	}

	// Toggle the .toggled class and the aria-expanded value each time the button is clicked.
	button.addEventListener( 'click', function() {
		siteNavigation.classList.toggle( 'toggled' );

		if ( button.getAttribute( 'aria-expanded' ) === 'true' ) {
			button.setAttribute( 'aria-expanded', 'false' );
		} else {
			button.setAttribute( 'aria-expanded', 'true' );
		}
	} );
	
	// Remove the .toggled class and set aria-expanded to false when the user clicks outside the navigation.
	document.addEventListener( 'click', function( event ) {
		const isClickInside = siteNavigation.contains( event.target );

		if ( ! isClickInside ) {
			
			page.classList.remove('page-rotate');
			htmlPage.classList.remove('scroll-disapple');
			toggleBtn.classList.remove('visible-menu');
			siteNavigation.classList.remove( 'toggled' );
			button.setAttribute( 'aria-expanded', 'false' );
		}
	} );


	  function menuRotate() {
		  if (mainMenu.classList.contains('toggled')) {
			  page.classList.add('page-rotate');
			  htmlPage.classList.add('scroll-disapple');
			  toggleBtn.classList.add('visible-menu');
		  }else{
			  page.classList.remove('page-rotate');
			  htmlPage.classList.remove('scroll-disapple');
			  toggleBtn.classList.remove('visible-menu');
		  }
	  }
	  toggleBtn.addEventListener('click', menuRotate);
	  mainMenuItmes.forEach((menuItem)=>{
		menuItem.addEventListener("click",()=>{
			page.classList.remove('page-rotate');
			htmlPage.classList.remove('scroll-disapple');
			toggleBtn.classList.remove('visible-menu');
			siteNavigation.classList.remove( 'toggled' );
			button.setAttribute( 'aria-expanded', 'false' );
		})
	  })
	  //   page.addEventListener('click',menuRotate);
	// /////////////////////////////////
}() );

    
 //hand shake animation-------------------------

 gsap.set((salamHand),{autoAlpha:0})
gsap.set((salamText),{autoAlpha:0})

function salamAnimation(){
    salamTl =gsap.timeline({yoyo:true,repeat:1, paused: true,repeatDelay:1});
    salamTl
    .to(myHand,{rotate:10,left:'45%',top:'70%' ,scale:.4,duration:.6},'start')
           .to(myHand,{autoAlpha:0,duration:.1})
          .to(yourHand,{rotate:10,right:'45%',top:'70%' ,scale:.4,duration:.6},'start')
          .to(yourHand,{autoAlpha:0,duration:0},'middle')
          .to(myHand,{autoAlpha:0,duration:0},'middle')
           .to(salamHand,{autoAlpha:1,bottom:-10,duration:.1},'middle-=.2')
           .to(salamHand,{bottom:10,duration:.1})
        
           .to(salamText,{autoAlpha:1 ,duration:.4,ease:Linear.easeNone},'middle-=.2')
return salamTl;
}

salamAnimation()
if(!salamTl.isActive())  {
    if(arrowPointer!=null){
    arrowPointer.classList.add('puppy-arrow');
}
    }
    if(myHand != null){
    myHand.addEventListener("click",salam )
    }
if(yourHand != null){
    yourHand.addEventListener("click",salam )
}

let i=0
function salam(){
   i++
    salamTl.restart();console.log('woo')
    arrowPointer.classList.remove('puppy-arrow');
    setTimeout(()=>{ arrowPointer.classList.add('puppy-arrow');},2000)
if(i==1){
    salamText.innerHTML = "nice to meet you (*^^*);";
}else if(i==2){
    salamText.innerHTML = "what's up friend (o^▽^o);";
}else if(i==3){
    salamText.innerHTML = 'wanna talk more!  (✯◡✯);<a href="#contact-me" class="">click here </a>';
    i=0
}
}
//----------------- Main page scroll animations
if(boxes !=null){
boxes.forEach(box => {

    gsap.to(box, { 
      duration:.25,
      scrollTrigger:{
          trigger:box,
          start:"top 40%",
          end:"center 0%",
          toggleActions:'restart reverse none none',
      //   markers:true,
          toggleClass:"box-inview-position"
      }
    })
  });
  }
  
  linesLeft.forEach(line=>{
   gsap.fromTo(line,
     {rotate:"-40deg",x:-800,opacity:0}
  ,{  
      rotate:"0",
      x:0,
      opacity:1,
      scrollTrigger:{
      trigger:"#contact-me",
      scrub:.5,
      start:`top bottom+=150`,
      end:"center 60%",
      toggleActions:'restart reverse none none',      
      // markers:true,
      }
   })
  })
  linesRight.forEach(line=>{
   gsap.fromTo(line,
     { rotate:"40deg",x:800,opacity:0}
  ,{ 
      rotate:"0",
      x:0,
      opacity:1,
      scrollTrigger:{
      trigger:"#contact-me",
      scrub:.5,
      start:`top bottom+=150`,
      end:"center 60%",
      toggleActions:'restart reverse none none',      
   // markers:true
      }
   })
  })
  
  
  
  gsap.to("#scroll-progress",{
       width: "100%",
      scrollTrigger:{
      scrub: 0.3,//lock the animation to scrollbar position
         toggleActions:'restart reverse none none',      
         
      }
  })
  const circlePaths = gsap.utils.toArray('.circle-path');
  
  circlePaths.forEach((cirlce) => {
    gsap.to(cirlce, {
      // strokeDasharray:'5 5',
      scrollTrigger:{
        trigger:"#my-skills",
       
        start:`top 90%`,
        end:"bottom bottom+100px ",
        toggleActions:'restart reverse none none',  
        toggleClass:{targets: cirlce, className: "movePath"},   
      //  markers:true,
        }
    });
  });
  // -----------------------
  
  function getItemOrigin(item){
    let xitemCenter=Math.round(item.getBBox().x+(item.getBBox().width/2)) 
    let yitemCenter=Math.round(item.getBBox().y+(item.getBBox().height/2)) 
  
    return `${xitemCenter} ${yitemCenter}`
  }
  
  gsap.to(starEye,{
    svgOrigin:getItemOrigin(starEye),
  
    rotate:360,
  
    scrollTrigger:{
      scrub:0.3,
      toggleActions:'restart reverse none none',      
         
    }
   })

  gsap.set('#circle-text',{rotate:25})
  
  let tween = gsap.to('#circle-text',
  {rotate:-25,
    ease: Bounce. easeOut,
    duration:1.5,
     scrollTrigger:{
  trigger:'#bymeCoffee',
  //toggleActions:'restart reverse none none',
  //markers:true
  }})
  //------------service worker-------------
//   if('serviceWorker' in navigator){
//   window.addEventListener('load',getAssets)
// // note: problem! servce worker is not updating on reload
//     function getAssets(){
//       navigator.serviceWorker
//       .register('service-worker.js')
//       .then(reg=>{})
//       .catch(err => console.log(`Service Worker:Error:${err}`))
//     }
//   }